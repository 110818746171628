@tailwind base;
@tailwind components;
@tailwind utilities;

/* allows the main portion of the page to have some size to it instead of
allowing the footer to squish <main> elements */
main {
  min-height: 70vh;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.social-icon {
  width: 1rem;
  height: 1rem;
}

.bg-st-blue {
  background-color: #0b233f;
}

.bg-st-babyblue {
  background-color: #a7c4e7;
}

.bg-st-goldenrod {
  background-color: #fbb349;
}

.text-st-blue {
  color: #0b233f;
}

.text-st-babyblue {
  color: #a7c4e7;
}

.text-st-goldenrod {
  color: #fbb349;
}

/* .bg-stphx-logo {
  background-image: url(./i/logo.png);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
} */

/* .bg-stphx-logo-blurred {
  background-image: url(./i/logo-b.png);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
} */

.bg-camelback {
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}